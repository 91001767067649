import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllDevicedata = createAsyncThunk(
    "Devices/getAllDevicedata",
    async () => {
        try {
            const response = await BaseUrl.get(`device`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllDeviceListdata = createAsyncThunk(
    "Devices/getAllDeviceListdata",
    async (type) => {
        try {
            const response = await BaseUrl.get(`device/machine-device?device_type=${type}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllDeviceCountdata = createAsyncThunk(
    "Devices/getAllDeviceCountdata",
    async () => {
        try {
            const response = await BaseUrl.get(`device/devicecount`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getDashboardDevicedata = createAsyncThunk(
    "Devices/getDashboardDevicedata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`device/devicedata/${id}`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getDeviceListdata = createAsyncThunk(
    "Devices/getDeviceListdata",
    async () => {
        try {
            const response = await BaseUrl.get(`device/devicesList`, getAuthHeaders());
            // console.log("DeviceList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleDevicedata = createAsyncThunk(
    "Devices/getSingleDevicedata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`device/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const addDevicedata = createAsyncThunk(
    "Devices/addDevicedata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`device`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const updateDevicedata = createAsyncThunk(
    "Devices/updateDevicedata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`device`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const deleteDevicedata = createAsyncThunk(
    "Devices/deleteDevicedata",
    async (id) => {
        try {
            const response = await BaseUrl.delete(`device/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "device",
    initialState: {
        deviceData: [],
        deviceListData: [],
        deviceCount: {},
        deviceDashboardData: [],
        deviceList: [],
        singleDeviceData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllDevicedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDevicedata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceData = action.payload;
            })
            .addCase(getAllDevicedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDashboardDevicedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardDevicedata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceDashboardData = action.payload;
            })
            .addCase(getDashboardDevicedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDeviceListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeviceListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceList = action.payload;
            })
            .addCase(getDeviceListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleDevicedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleDevicedata.fulfilled, (state, action) => {
                state.loading = false;
                state.singleDeviceData = action.payload;
            })
            .addCase(getSingleDevicedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllDeviceCountdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDeviceCountdata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceCount = action.payload;
            })
            .addCase(getAllDeviceCountdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllDeviceListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDeviceListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceListData = action.payload;
            })
            .addCase(getAllDeviceListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getDevicesData = (state) => state.device.deviceData;
export const getDevicescountData = (state) => state.device.deviceCount;
export const getDevicesListData = (state) => state.device.deviceListData;
export const getdeviceDashboard = (state) => state.device.deviceDashboardData;
export const deviceListsData = (state) => state.device.deviceList;
export const getSingleDevicesData = (state) => state.device.singleDeviceData;
export const deviceError = (state) => state.device.error;

// EXPORTS
export default loginSlice.reducer;