import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllMachinedata = createAsyncThunk(
    "Machines/getAllMachinedata",
    async () => {
        try {
            const response = await BaseUrl.get(`machine`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getDashboardMachinedata = createAsyncThunk(
    "Machines/getDashboardMachinedata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`machine/machinedata/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);


export const getDashboardOilVenderandManufacturerData = createAsyncThunk(
    "Machines/getOilVendorAndMachineMachineDashboard",
    async (id) => {
        try {
            const response = await BaseUrl.get(`machine/oil-vendor-and-manufacture-machines/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getMachineListdata = createAsyncThunk(
    "Machines/getMachineListdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`machine/machinelist/${id}`, getAuthHeaders());
            // console.log("MachineList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const addMachinedata = createAsyncThunk(
    "Machines/addMachinedata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`machine`, data, getAuthHeaders());
            // console.log("MachineList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const updateMachinedata = createAsyncThunk(
    "Machines/updateMachinedata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`machine`, data, getAuthHeaders());
            // console.log("MachineList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const deletetMachinedata = createAsyncThunk(
    "Machines/deletetMachinedata",
    async (id) => {
        try {
            const response = await BaseUrl.delete(`machine/${id}`, getAuthHeaders());
            // console.log("MachineList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleMachinedata = createAsyncThunk(
    "Machines/getSingleMachinedata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`machine/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getOilVendorMachinedata = createAsyncThunk(
    "Machines/getOilVendorMachinedata",
    async () => {
        try {
            const response = await BaseUrl.get(`machine/oil-vendor`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getOilManufacturerMachinedata = createAsyncThunk(
    "Machines/getOilManufacturerMachinedata",
    async () => {
        try {
            const response = await BaseUrl.get(`machine/oil-manufacture`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "machine",
    initialState: {
        MachineData: [],
        dashboardMachineData: [],
        dashboardMachineOilVendandmanuData: [],
        MachineList: [],
        oilVendorData: [],
        oilManufacturerData: [],
        singleMachineData: {},
        noOfMachine: 0,
        companyName: '',
        plantName: '',
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllMachinedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllMachinedata.fulfilled, (state, action) => {
                state.loading = false;
                state.MachineData = action.payload;
            })
            .addCase(getAllMachinedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getMachineListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMachineListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.MachineList = action.payload;
            })
            .addCase(getMachineListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleMachinedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleMachinedata.fulfilled, (state, action) => {
                state.loading = false;
                state.singleMachineData = action.payload;
            })
            .addCase(getSingleMachinedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getOilVendorMachinedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOilVendorMachinedata.fulfilled, (state, action) => {
                state.loading = false;
                state.oilVendorData = action.payload;
            })
            .addCase(getOilVendorMachinedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getOilManufacturerMachinedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOilManufacturerMachinedata.fulfilled, (state, action) => {
                state.loading = false;
                state.oilManufacturerData = action.payload;
            })
            .addCase(getOilManufacturerMachinedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDashboardMachinedata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardMachinedata.fulfilled, (state, action) => {
                state.loading = false;
                state.noOfMachine = action.payload.noOfRecords;
                state.companyName = action.payload.company_name;
                state.plantName = action.payload.plant_name;
                state.dashboardMachineData = action.payload.data;
            })
            .addCase(getDashboardMachinedata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDashboardOilVenderandManufacturerData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDashboardOilVenderandManufacturerData.fulfilled, (state, action) => {
                state.loading = false;
                state.companyName = action.payload.company_name;
                state.plantName = action.payload.plant_name;
                state.dashboardMachineOilVendandmanuData = action.payload.data;
            })
            .addCase(getDashboardOilVenderandManufacturerData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getTotalMachine = (state) => state.machine.noOfMachine;
export const getMachinesData = (state) => state.machine.MachineData;
export const getDashboardMachine = (state) => state.machine.dashboardMachineData;
export const getMachineCompany = (state) => state.machine.companyName;
export const getMachinePlant = (state) => state.machine.plantName;
export const getdashboardMachineOilVendandmanu = (state) => state.machine.dashboardMachineOilVendandmanuData;
export const getOilVendoData = (state) => state.machine.oilVendorData;
export const getOilManufacturerData = (state) => state.machine.oilManufacturerData;
export const machineListsData = (state) => state.machine.MachineList;
export const getSingleMachinesData = (state) => state.machine.singleMachineData;
export const machineError = (state) => state.machine.error;

// EXPORTS
export default loginSlice.reducer;