import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllCompanydata = createAsyncThunk(
    "Companys/getAllCompanydata",
    async () => {
        try {
            const response = await BaseUrl.get(`company`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getCompanyListdata = createAsyncThunk(
    "Companys/getCompanyListdata",
    async () => {
        try {
            const response = await BaseUrl.get(`company/companylist`, getAuthHeaders());
            // console.log("companyList==>", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleCompanydata = createAsyncThunk(
    "Companys/getSingleCompanydata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`company/${id}`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getCompanyDashBoard = createAsyncThunk(
    "Companys/getCompanyDashBoard",
    async () => {
        try {
            const response = await BaseUrl.get(`company/companydata/`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getOilVendorAndManufactureCompanyDashBoard = createAsyncThunk(
    "Companys/getOilVendorAndManufactureCompanyDashBoard",
    async () => {
        try {
            const response = await BaseUrl.get(`machine/oil-vendor-and-manufacture-company`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const addCompanydata = createAsyncThunk(
    "Companys/addCompanydata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`company`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const UpdateCompanydata = createAsyncThunk(
    "Companys/UpdateCompanydata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`company`, data, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const deleteCompanydata = createAsyncThunk(
    "Companys/deleteCompanydata",
    async (id) => {
        try {
            const response = await BaseUrl.delete(`company/${id}`, getAuthHeaders());
            console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "company",
    initialState: {
        CompanyData: [],
        comapnyList: [],
        comapnyDashboardData: [],
        oilVandMComapnyDashboardData: [],
        singleCompanyData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCompanydata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCompanydata.fulfilled, (state, action) => {
                state.loading = false;
                state.CompanyData = action.payload;
            })
            .addCase(getAllCompanydata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getCompanyListdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyListdata.fulfilled, (state, action) => {
                state.loading = false;
                state.comapnyList = action.payload;
            })
            .addCase(getCompanyListdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleCompanydata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleCompanydata.fulfilled, (state, action) => {
                state.loading = false;
                state.singleCompanyData = action.payload;
            })
            .addCase(getSingleCompanydata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getCompanyDashBoard.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyDashBoard.fulfilled, (state, action) => {
                state.loading = false;
                state.comapnyDashboardData = action.payload;
            })
            .addCase(getCompanyDashBoard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getOilVendorAndManufactureCompanyDashBoard.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOilVendorAndManufactureCompanyDashBoard.fulfilled, (state, action) => {
                state.loading = false;
                state.oilVandMComapnyDashboardData = action.payload;
            })
            .addCase(getOilVendorAndManufactureCompanyDashBoard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getCompanysData = (state) => state.company.CompanyData;
export const getCompanyListsData = (state) => state.company.comapnyList;
export const getcomapnyDashboardData = (state) => state.company.comapnyDashboardData;
export const getOilVandMComapnyDashboard = (state) => state.company.oilVandMComapnyDashboardData;
export const getSingleCompanysData = (state) => state.company.singleCompanyData;
export const companyError = (state) => state.company.error;

// EXPORTS
export default loginSlice.reducer;