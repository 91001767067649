import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("samyojak");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllDeviceRawDatadata = createAsyncThunk(
    "DeviceRawDatas/getAllDeviceRawDatadata",
    async () => {
        try {
            const response = await BaseUrl.get(`devicerawdata`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getMeterMaxMinReading = createAsyncThunk(
    "DeviceRawDatas/getMeterMaxMinReading",
    async (id) => {
        try {
            const response = await BaseUrl.get(`meter-reading/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllDeviceProccessDatadata = createAsyncThunk(
    "DeviceRawDatas/getAllDeviceProccessDatadata",
    async () => {
        try {
            const response = await BaseUrl.get(`deviceprocessdata`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getDeviceSummeryReportData = createAsyncThunk(
    "DeviceRawDatas/getDeviceSummeryReportData",
    async ({ machine_id, currentDate }) => {
        try {
            const response = await BaseUrl.get(`devicerawdata/summarydaydata?machine_id=${machine_id}&currentDate=${currentDate}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getDeviceDetailedReportData = createAsyncThunk(
    "DeviceRawDatas/getDeviceDetailedReportData",
    async ({ machine_id, fromD, toD }) => {
        // console.log("machine_id, startDate, endData", machine_id, fromD, toD)
        try {
            const response = await BaseUrl.get(`devicerawdata/summarybetweendates?machine_id=${machine_id}&startDate=${fromD}&endDate=${toD}`, getAuthHeaders());
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleDeviceRawDatadata = createAsyncThunk(
    "DeviceRawDatas/getSingleDeviceRawDatadata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`devicerawdata/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const getSingleDeviceStatusData = createAsyncThunk(
    "DeviceRawDatas/getSingleDeviceStatusData",
    async (maachineData) => {
        try {
            const response = await BaseUrl.post(`devicestatusdata/statusdata`, { machine_id: maachineData }, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

export const addDeviceRawDatadata = createAsyncThunk(
    "DeviceRawDatas/addDeviceRawDatadata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`devicerawdata`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const UpdateDeviceRawDatadata = createAsyncThunk(
    "DeviceRawDatas/UpdateDeviceRawDatadata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`devicerawdata`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "devicerawdata",
    initialState: {
        deviceRawDataData: [],
        deviceProccessDataData: [],
        deviceSummeryData: [],
        deviceDetailedData: [],
        meterReadings: {},
        singleDeviceRawDataData: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeviceRawDatadata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDeviceRawDatadata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceRawDataData = action.payload;
            })
            .addCase(getAllDeviceRawDatadata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleDeviceStatusData.pending, (state) => {
                state.loading = true;
                state.singleDeviceRawDataData = {};
            })
            .addCase(getSingleDeviceStatusData.fulfilled, (state, action) => {
                state.loading = false;
                state.singleDeviceRawDataData = action.payload;
            })
            .addCase(getSingleDeviceStatusData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllDeviceProccessDatadata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDeviceProccessDatadata.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceProccessDataData = action.payload;
            })
            .addCase(getAllDeviceProccessDatadata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDeviceSummeryReportData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeviceSummeryReportData.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceSummeryData = action.payload;
            })
            .addCase(getDeviceSummeryReportData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getDeviceDetailedReportData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeviceDetailedReportData.fulfilled, (state, action) => {
                state.loading = false;
                state.deviceDetailedData = action.payload;
            })
            .addCase(getDeviceDetailedReportData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getMeterMaxMinReading.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMeterMaxMinReading.fulfilled, (state, action) => {
                state.loading = false;
                state.meterReadings = action.payload;
            })
            .addCase(getMeterMaxMinReading.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getDeviceRawDatasData = (state) => state.devicerawdata.deviceRawDataData;
export const getMeterReadingData = (state) => state.devicerawdata.meterReadings;
export const getDeviceProccessData = (state) => state.devicerawdata.deviceProccessDataData;
export const getDeviceSummeryData = (state) => state.devicerawdata.deviceSummeryData;
export const getDeviceDetailedData = (state) => state.devicerawdata.deviceDetailedData;
export const getSingleDeviceStatus = (state) => state.devicerawdata.singleDeviceRawDataData;
export const deviceRawDataError = (state) => state.devicerawdata.error;

// EXPORTS
export default loginSlice.reducer;