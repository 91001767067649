import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { UseAuth } from '../customhooks/UseAuth'
import ProfileTopBarMenu from '../utils/ProfileTopBarMenu';
import { useNavigate } from 'react-router-dom';

const TopBarComponent = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const { userId, roleId, name } = UseAuth();
    const styles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItem: "center",
        position: "sticky",
        top: "0px",
        zIndex: 99,
        boxSadhow: "5px 0 10px black",
        p: 1
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAccount = () => {
        navigate(`/samyojak/updateusers/${userId}`);
        setAnchorEl(null);
    };
    const logOut = () => {
        navigate('/')
        localStorage.clear('samyojak')
    }
    return (
        <>
            <Box sx={styles} bgcolor={"white"} borderBottom={"2px lightgrey solid"}>
                {/* <Avatar style={{ width: 60, height: 60, boxShadow: "0 0 5px black" }} src='/samyojak_logo.jpeg' alt='samyojak_logo.jpeg' /> */}
                <Typography variant='h6'>
                    Welcome to Samyojak
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant='body' color={"Highlight"}>
                            {name}
                        </Typography>
                        <Typography variant='body' color={"red"}>
                            {roleId === 2000 ? "Super Admin" : roleId === 3000 ? "Company Admin" : roleId === 4000 ? "Plant Admin" : roleId === 5000 ? "Machine Admin" : roleId === 6000 ? "Oil Vendor" : roleId === 7000 ? "Oil Manufacturer" : ""}
                        </Typography>
                    </Box>
                    <ProfileTopBarMenu anchorel={anchorEl} handleclose={handleClose} handleaccount={handleAccount} handlemenu={handleMenu} logout={logOut} />
                </Box>
            </Box>
        </>
    )
}

export default TopBarComponent