import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducerSlice/userSlice";
import companySlice from "./reducerSlice/companySlice";
import plantSlice from "./reducerSlice/plantSlice";
import machineSlice from "./reducerSlice/machineSlice";
import deviceSlice from "./reducerSlice/deviceSlice";
import deviceRawDataSlice from "./reducerSlice/deviceRawData";

export const store = configureStore({
    reducer: {
        auth: userSlice,
        company: companySlice,
        plant: plantSlice,
        machine: machineSlice,
        device: deviceSlice,
        devicerawdata: deviceRawDataSlice,
    }
})