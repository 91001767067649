import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

export const UseAuth = () => {
    const token = localStorage.getItem("samyojak");
    // const resToken = JSON.parse(token);
    if (!token) {
        return <Navigate to='/' />
    }
    if (token) {
        try {
            const decode = jwtDecode(token);
            const { id, name, email, role_id, company_id, plant_id } = decode;
            // console.log("user data ==>>", decode)
            return { userId: id, name, email, roleId: role_id, cId: company_id, pId: plant_id, token: token }; // Fixed typo in 'useRole'
        } catch (error) {
            // Handle decoding error here
            console.error("Error decoding token:", error);
        }
    }
}