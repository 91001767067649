import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'

const ProfileTopBarMenu = ({ anchorel, handleclose, handlemenu, handleaccount, logout }) => {
    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handlemenu}
                color="inherit"
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorel={anchorel}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorel)}
                onClose={handleclose}
            >
                <MenuItem onClick={handleaccount}>My account</MenuItem>
                <MenuItem onClick={logout}>Log Out</MenuItem>
            </Menu>
        </>
    )
}

export default ProfileTopBarMenu