import { Navigate } from "react-router-dom";
import { UseAuth } from "../../customhooks/UseAuth";

const AuthGuard = ({ children }) => {
    const { roleId } = UseAuth();
    console.log("AuthGuard", roleId)
    const authRoles = [
        2000,
        3000,
        4000,
        5000,
        6000,
        7000
    ]
    if (roleId === undefined) {
        return <Navigate to={'/'} />
    }
    const rolesMatch = authRoles.includes(roleId);
    console.log("rolesMatch==>", rolesMatch)
    if (!rolesMatch) {
        return <Navigate to={'/'} />
    }
    return children;
}

export default AuthGuard